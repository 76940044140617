import Vue from 'vue'
import VueRouter from 'vue-router'
import Swal from 'sweetalert2'
// Routes
import axios from 'axios'
import monolit from './routes/monolit'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'monolit.dashboard' } },
    ...monolit,
    // ...apps,
  ],
})

router.beforeEach((to, from, next) => {
  if (from.name === 'monolit.accounting.list') {
    Swal.fire({
      title: 'Biztosan elhagyod az oldalt?',
      text: 'A nem mentett adatok elvesznek',
      showDenyButton: false,
      customClass: {
        confirmButton: 'btn btn-primary ml-1',
        cancelButton: 'btn btn-danger mr-1',
      },
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem',
    }).then(result => {
      if (result.isConfirmed) {
        next()
      } else {
        return false
      }
    })
  } else {
    if (to.matched.some(record => record.meta.roles)) {
      if (localStorage.getItem('user') !== null) {
        const userRole = JSON.parse(localStorage.getItem('user'))
        if (userRole.role.includes(to.meta.roles)) {
          axios({
            method: 'get',
            url: 'auth/checkAuthToken',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(response => {
            if (response.status !== 200) {
              localStorage.removeItem('user')
              localStorage.removeItem('token')
              router.push({ name: 'monolit.login' })
            }
            next()
          }).catch(() => {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            router.push({ name: 'monolit.login' })
          })
        } else {
          router.push({ name: 'monolit.login' })
        }
      } else {
        router.push({ name: 'monolit.login' })
      }
    }
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

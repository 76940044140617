export default [
  {
    path: '/login',
    name: 'monolit.login',
    component: () => import('@/app/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/',
    name: 'monolit.dashboard',
    component: () => import('@/app/Dashboard.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'list',
      roles: 'dashboard',
    },
  },
  {
    path: '/projects/todo/:id',
    name: 'monolit.todo',
    component: () => import('@/app/Projects/Todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'Todo',
      action: 'show',
      roles: ['Management', 'Preparatory sweat', 'Preparatory', 'Foreman', 'Office', 'Warehouseman', null],
    },
    children: [
      {
        path: '/projects/todo/:id/:filter',
        name: 'monolit.projects.todo.filter',
        component: () => import('@/app/Projects/Todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          resource: 'Todo',
          action: 'show',
          roles: ['Management', 'Preparatory sweat', 'Preparatory', 'Foreman', 'Office', 'Warehouseman', null],
        },
      },
    ],
  },
  {
    path: '/projects/documentum/create/:id',
    name: 'monolit.document.create',
    component: () => import('@/app/Projects/Generator/Create.vue'),
    meta: {
      resource: 'Documentum',
      action: 'show',
      roles: ['Management', 'Preparatory sweat', 'Preparatory', 'Foreman', 'Office', 'Warehouseman', null],
    },
  },
  {
    path: '/global/todo',
    name: 'monolit.global.todo',
    component: () => import('@/app/Globals/Todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'Todo',
      action: 'show',
      roles: 'todo',
    },
    children: [
      {
        path: '/global/todo/:filter',
        name: 'monolit.global.todo.filter',
        component: () => import('@/app/Globals/Todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          resource: 'Todo',
          action: 'show',
          roles: 'todo',
        },
      },
      {
        path: '/global/todo/:filter/:id',
        name: 'monolit.global.todo.filter.id',
        component: () => import('@/app/Globals/Todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          resource: 'Todo',
          action: 'show',
          roles: 'todo',
        },
      },
    ],
  },
  {
    path: '/subcontractor/create',
    name: 'monolit.subcontractor.create',
    component: () => import('@/app/Subcontractor/Create.vue'),
    meta: {
      resource: 'Subcontractor',
      action: 'create',
      roles: 'subcontractor',
    },
  },
  {
    path: '/subcontractor/edit/:id',
    name: 'monolit.subcontractor.edit',
    component: () => import('@/app/Subcontractor/Edit.vue'),
    meta: {
      resource: 'Subcontractor',
      action: 'create',
      roles: 'subcontractor',
    },
  },
  {
    path: '/freedays/list',
    name: 'monolit.freedays.list',
    component: () => import('@/app/Freedays/list.vue'),
    meta: {
      resource: 'FreeDays',
      action: 'create',
      roles: 'freeday_menu',
    },
  },
  {
    path: '/freedays/own/foreman',
    name: 'monolit.freeday.own',
    component: () => import('@/app/Freedays/foreman.vue'),
    meta: {
      resource: 'FreeDays',
      action: 'create',
      roles: 'foreman_freeday',
    },
  },
  {
    path: '/freedays/own/leader',
    name: 'monolit.freeday.own.leader',
    component: () => import('@/app/Freedays/leader.vue'),
    meta: {
      resource: 'FreeDays',
      action: 'create',
      roles: 'leader_freeday',
    },
  },
  {
    path: '/freedays/year/list',
    name: 'monolit.freeday.year.list',
    component: () => import('@/app/Freedays/year.vue'),
    meta: {
      resource: 'FreeDays',
      action: 'create',
      roles: 'freeday_menu',
    },
  },
  {
    path: '/freedays/global/list',
    name: 'monolit.freeday.global.list',
    component: () => import('@/app/Freedays/global.vue'),
    meta: {
      resource: 'FreeDays',
      action: 'create',
      roles: 'freeday_menu',
    },
  },
  {
    path: '/extra-fields/list',
    name: 'monolit.extrafields.list',
    component: () => import('@/app/ExtraFields/list.vue'),
    meta: {
      resource: 'ExtraFeilds',
      action: 'create',
      roles: 'extra_fields',
    },
  },
  {
    path: '/subcontractor/list',
    name: 'monolit.subcontractor.list',
    component: () => import('@/app/Subcontractor/List.vue'),
    meta: {
      resource: 'Subcontractor',
      action: 'list',
      roles: 'subcontractor',
    },
  },
  {
    path: '/employee/list',
    name: 'monolit.employee.list',
    component: () => import('@/app/Employees/list.vue'),
    meta: {
      resource: 'Employees',
      action: 'list',
      roles: 'employee_menu',
    },
  },
  {
    path: '/cars/list',
    name: 'monolit.car.list',
    component: () => import('@/app/Cars/list.vue'),
    meta: {
      resource: 'Cars',
      action: 'list',
      roles: 'car_menu',
    },
  },
  {
    path: '/cars/create',
    name: 'monolit.car.create',
    component: () => import('@/app/Cars/create.vue'),
    meta: {
      resource: 'Cars',
      action: 'list',
      roles: 'car_menu',
    },
  },
  {
    path: '/cars/edit/:id',
    name: 'monolit.car.edit',
    component: () => import('@/app/Cars/edit.vue'),
    meta: {
      resource: 'Cars',
      action: 'list',
      roles: 'car_menu',
    },
  },
  {
    path: '/cars/create',
    name: 'monolit.cars.create',
    component: () => import('@/app/Cars/create.vue'),
    meta: {
      resource: 'Cars',
      action: 'list',
      roles: 'car_menu',
    },
  },
  {
    path: '/employee/create',
    name: 'monolit.employee.create',
    component: () => import('@/app/Employees/create.vue'),
    meta: {
      resource: 'Employees',
      action: 'list',
      roles: 'employee_menu',
    },
  },
  {
    path: '/employee/archive',
    name: 'monolit.employee.archive',
    component: () => import('@/app/Employees/archive.vue'),
    meta: {
      resource: 'Employees',
      action: 'list',
      roles: 'employee_menu',
    },
  },
  {
    path: '/employee/edit/:id',
    name: 'monolit.employee.edit',
    component: () => import('@/app/Employees/edit.vue'),
    meta: {
      resource: 'Employees',
      action: 'list',
      roles: 'employee_menu',
    },
  },
  {
    path: '/projects/create/full',
    name: 'monolit.projects.create.full',
    component: () => import('@/app/Projects/CreateFull.vue'),
    meta: {
      resource: 'Projects',
      action: 'create',
      roles: 'project',
    },
  },
  {
    path: '/projects/create/full/:id',
    name: 'monolit.projects.create.full.filter',
    component: () => import('@/app/Projects/CreateFull.vue'),
    meta: {
      resource: 'Projects',
      action: 'create',
      roles: 'project',
    },
  },
  {
    path: '/projects/create',
    name: 'monolit.projects.create',
    component: () => import('@/app/Projects/Create.vue'),
    meta: {
      resource: 'Projects',
      action: 'create',
      roles: 'todo',
    },
  },
  {
    path: '/project/list',
    name: 'monolit.projects.list',
    component: () => import('@/app/Projects/List.vue'),
    meta: {
      resource: 'Projects',
      action: 'list',
      roles: 'project',
    },
  },
  {
    path: '/project/list-all',
    name: 'monolit.projects.list.all',
    component: () => import('@/app/Projects/ListAll.vue'),
    meta: {
      resource: 'Projects',
      action: 'list',
      roles: 'project',
    },
  },
  {
    path: '/project/list/archive',
    name: 'monolit.projects.list.archive',
    component: () => import('@/app/Projects/Archive.vue'),
    meta: {
      resource: 'Projects',
      action: 'list',
      roles: 'project',
    },
  },
  {
    path: '/invoice/list',
    name: 'monolit.invoice.list',
    component: () => import('@/app/Invoice/List.vue'),
    meta: {
      resource: 'Invoice',
      action: 'list',
      roles: 'invoice_progress',
    },
  },
  {
    path: '/credit/list',
    name: 'monolit.credit.list',
    component: () => import('@/app/Credit/List.vue'),
    meta: {
      resource: 'Credit',
      action: 'list',
      roles: 'credit_menu',
    },
  },
  {
    path: '/credit/archive',
    name: 'monolit.credit.archive',
    component: () => import('@/app/Credit/Archive.vue'),
    meta: {
      resource: 'Credit',
      action: 'list',
      roles: 'credit_menu',
    },
  },
  {
    path: '/invoice/list/archive',
    name: 'monolit.invoice.list.archive',
    component: () => import('@/app/Invoice/Archive.vue'),
    meta: {
      resource: 'Invoice',
      action: 'list',
      roles: 'invoice_progress',
    },
  },
  {
    path: '/invoice/list/all',
    name: 'monolit.invoice.list.all',
    component: () => import('@/app/Invoice/All.vue'),
    meta: {
      resource: 'Invoice',
      action: 'list',
      roles: 'invoice_progress',
    },
  },
  {
    path: '/invoice/list/Project',
    name: 'monolit.invoice.list.project',
    component: () => import('@/app/Invoice/Project.vue'),
    meta: {
      resource: 'Invoice',
      action: 'list',
      roles: 'invoice_progress',
    },
  },
  {
    path: '/project/edit/:id',
    name: 'monolit.projects.edit',
    component: () => import('@/app/Projects/Edit.vue'),
    meta: {
      resource: 'Projects',
      action: 'list',
      roles: 'project',
    },
  },
  {
    path: '/project/list/documents/:id',
    name: 'monolit.projects.documents',
    component: () => import('@/app/Projects/Documents/List.vue'),
    meta: {
      resource: 'Documents',
      action: 'list',
      roles: 'documents_list',
    },
  },
  {
    path: '/project/list/documents/archive/:id',
    name: 'monolit.projects.documents',
    component: () => import('@/app/Projects/Documents/Archive.vue'),
    meta: {
      resource: 'Archive',
      action: 'list',
      roles: 'documents_list',
    },
  },
  {
    path: '/project/list/invoice/:id',
    name: 'monolit.projects.invoice',
    component: () => import('@/app/Projects/Invoice/List.vue'),
    meta: {
      resource: 'Projects',
      action: 'list',
      roles: 'project',
    },
  },
  {
    path: '/preparation/todo/:id',
    name: 'monolit.preparation.todo',
    component: () => import('@/app/Preparation/Todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'Todo',
      action: 'show',
      roles: ['Management', 'Preparatory sweat', 'Preparatory', 'Foreman', 'Office', 'Warehouseman', null],
    },
    children: [
      {
        path: '/preparation/todo/:id/:filter',
        name: 'monolit.preparation.todo.filter',
        component: () => import('@/app/Preparation/Todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          resource: 'Todo',
          action: 'show',
          roles: ['Management', 'Preparatory sweat', 'Preparatory', 'Foreman', 'Office', 'Warehouseman', null],
        },
      },
    ],
  },
  {
    path: '/user/list',
    name: 'monolit.user.list',
    component: () => import('@/app/User/List.vue'),
    meta: {
      resource: 'User',
      action: 'list',
      roles: 'user',
    },
  },
  {
    path: '/user/create',
    name: 'monolit.user.create',
    component: () => import('@/app/User/Create.vue'),
    meta: {
      resource: 'User',
      action: 'create',
      roles: 'user',
    },
  },
  {
    path: '/order/create',
    name: 'monolit.order.create',
    component: () => import('@/app/Order/Create.vue'),
    meta: {
      resource: 'Order',
      action: 'create',
      roles: 'create_order',
    },
  },
  {
    path: '/order/list',
    name: 'monolit.order.list.list',
    component: () => import('@/app/Order/List.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'progress_order',
    },
  },
  {
    path: '/order/list/:id',
    name: 'monolit.order.list.list.filter',
    component: () => import('@/app/Order/List.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'progress_order',
    },
  },
  {
    path: '/order/list/archive',
    name: 'monolit.order.list.archive',
    component: () => import('@/app/Order/Archive.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'progress_order',
    },
  },
  {
    path: '/order/own',
    name: 'monolit.order.own',
    component: () => import('@/app/Order/Own.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'create_order',
    },
  },
  {
    path: '/order/own-archive',
    name: 'monolit.order.own.archive',
    component: () => import('@/app/Order/OwnArchive.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'create_order',
    },
  },
  {
    path: '/order/invoice-list',
    name: 'monolit.order.invoice.list',
    component: () => import('@/app/Order/InvoiceList.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'invoices_offer',
    },
  },
  {
    path: '/report/list',
    name: 'monolit.report.list',
    component: () => import('@/app/Report/List.vue'),
    meta: {
      resource: 'Report',
      action: 'List',
      roles: 'report',
    },
  },
  {
    path: '/viber/settings',
    name: 'monolit.viber.settings',
    component: () => import('@/app/Viber/Settings.vue'),
    meta: {
      resource: 'Viber',
      action: 'List',
      roles: 'viber',
    },
  },
  {
    path: '/order/invoice-list-archive',
    name: 'monolit.order.invoice.list.archive',
    component: () => import('@/app/Order/InvoiceListArchived.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'invoices_offer',
    },
  },
  {
    path: '/order/own-archive/:id',
    name: 'monolit.order.own.archive.filter',
    component: () => import('@/app/Order/OwnArchive.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'create_order',
    },
  },
  {
    path: '/order/own/:id',
    name: 'monolit.order.own.filter',
    component: () => import('@/app/Order/Own.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'create_order',
    },
  },
  {
    path: '/project/total-offers',
    name: 'monolit.project.total.offers',
    component: () => import('@/app/Offers/Total.vue'),
    meta: {
      resource: 'List',
      action: 'List',
      roles: 'create_order',
    },
  },
  {
    path: '/private_comment/list',
    name: 'monolit.private_comment.list',
    component: () => import('@/app/PrivateComment/List.vue'),
    meta: {
      resource: 'User',
      action: 'create',
      roles: 'private_comment',
    },
  },
  {
    path: '/private_comment/archive',
    name: 'monolit.private_comment.archive',
    component: () => import('@/app/PrivateComment/Archive.vue'),
    meta: {
      resource: 'User',
      action: 'create',
      roles: 'private_comment',
    },
  },
  {
    path: '/accounting/year',
    name: 'monolit.accounting.year',
    component: () => import('@/app/Accounting/Year.vue'),
    meta: {
      resource: 'User',
      action: 'list',
      roles: 'accounting',
    },
  },
  {
    path: '/accounting/list/:date',
    name: 'monolit.accounting.list',
    component: () => import('@/app/Accounting/List.vue'),
    meta: {
      resource: 'User',
      action: 'list',
      roles: 'accounting',
    },
  },
  {
    path: '*',
    name: 'monolit.404',
    component: () => import('@/app/Error404.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
]
